import type { User } from "~/models/User"

export function useCan() {
    const {user} = useSanctumAuth<User>()
    function can(permission: string[] | string|undefined): boolean {

        if (permission && user.value) {
    
            if (typeof permission == 'string') {
    
                return user.value.permissions.findIndex(p => p == permission) != -1
            }
    
            for (const permissionElement of permission) {
    
                if (user.value.permissions.findIndex(p => p == permissionElement) != -1) {
    
                    return true
                }
            }
    
            return false
        }
    
        return false
    }

    return can
}
